.Navbar {
  pointer-events: none;

  position: absolute;
  z-index: 2;
  top: 196px;
  right: var(--size-xxs);
  bottom: var(--size-xxs);
  left: var(--size-xxs);

  overflow: hidden;
  display: flex;

  min-height: 80px;

  transition: transform 0.8s ease-in-out;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.Navbar:not(.isReady) {
  transform: translateY(200px);
}

.Navbar.active {
  pointer-events: all;
}

.Navbar.active .Navbar-content {
  transform: translateY(0);
  opacity: 1;
}

.Navbar.active .Navbar-breadcrumbs {
  transform: translateY(0);
  opacity: 1;
}

.Navbar.active .Navbar-overview {
  transform: translateY(150%);
  opacity: 0;
}

.Navbar-breadcrumbs {
  transform: translateY(110%);
  opacity: 0;

  flex: 1;

  max-width: 80px;
  margin-right: 1px;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;

  transition: 0.3s ease-in-out;
}

.Navbar-content {
  transform: translateY(110%);
  opacity: 0;

  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  max-height: 100%;

  transition: 0.3s ease-in-out;
}

.Navbar-content:focus {
  outline: none;
}

.Navbar-overview {
  pointer-events: all;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  height: 80px;

  transition: 0.3s ease-in-out;
}

.Navbar-button {
  pointer-events: all;
  cursor: pointer;

  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transition: opacity 0.8s 2s ease-in-out;
}

.Navbar-button.isReady {
  bottom: 92px;
  opacity: 1;
}

.Navbar-button:focus {
  outline: none;
}

.Navbar-button.active {
  top: 145px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.Navbar-arrowDown,
.Navbar-button.active .Navbar-arrowUp {
  display: none;
}

.Navbar-arrowUp,
.Navbar-button.active .Navbar-arrowDown {
  display: block;
}

.Navbar-breadcrumb {
  flex: 1;

  height: 80px;
  margin-right: 1px;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.Navbar-regionStats {
  display: flex;
  gap: var(--size-xs);

  width: 100%;
  padding: var(--size-xs) var(--size-xs);

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.Navbar-regionStatsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: 1;
  row-gap: var(--size-xxs);
  column-gap: var(--size-sm);
}

.Stat-highlight {
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .Navbar {
    pointer-events: all;

    top: var(--size-lg);
    right: initial;
    bottom: var(--size-lg);
    left: var(--size-lg);
  }

  .Navbar:not(.isReady) {
    transform: translateX(-400px);
  }

  .Navbar .Navbar-content {
    transform: translateY(0) translateX(-104px);
    width: 305px;
    opacity: 1;
  }

  .Navbar .Navbar-breadcrumbs {
    transform: translateX(-105px);
    max-width: 104px;
  }

  .Navbar.expanded .Navbar-content {
    transform: translateY(0);
    opacity: 1;
  }

  .Navbar.expanded .Navbar-breadcrumbs {
    transform: translateX(0%);
    opacity: 1;
  }

  .Navbar-overview {
    display: none;
  }

  .Navbar-button {
    display: none;
  }
}
