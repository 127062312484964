.Logo,
.Logo-load {
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 12px;

  max-width: 141px;
}

.Logo-load {
  animation: blinker 1s linear infinite;
}

@media screen and (min-width: 1024px) {
  .Logo,
  .Logo-load {
    position: absolute;
    z-index: 1;
    top: initial;
    right: 32px;
    bottom: 48px;
    left: initial;

    max-width: 212px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
