.Breadcrumb {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 78px;
  min-height: 80px;
  padding: var(--size-xs) 2px;

  font-family: var(--font-family);
  font-size: var(--size-sm);
  font-weight: 400;
  color: var(--color-white);

  opacity: 0.6;
  background: none;
  border: 1px solid transparent;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.Breadcrumb.active,
.Breadcrumb:hover,
.Breadcrumb:focus {
  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.576) 2.96%,
    rgba(0, 133, 255, 0.5056) 50.03%,
    rgba(0, 163, 255, 0.5504) 93.31%
  );
  outline: none;
}

.Breadcrumb.active {
  opacity: 1;
  backdrop-filter: blur(var(--size-base));
  border-top: 1.5px solid;
  border-right: 1.5px solid;
  border-bottom: 1.5px solid;
  border-left: 1.5px solid;
  border-image: linear-gradient(
      to right,
      rgba(16, 255, 212, 1),
      rgba(16, 255, 212, 0.12)
    )
    1;
  border-image-outset: 1px 0 0 1px;
}

.Breadcrumb-icon {
  width: auto;
  height: 28px;
  margin-bottom: var(--size-xxs);
}

.Breadcrumb-icon path {
  fill: var(--color-white);
}

.Breadcrumb-name {
  font-size: var(--size-sm);
  word-wrap: nowrap;
}

.Breadcrumb.active .Breadcrumb-icon path {
  fill: var(--color-green);
}

@media screen and (min-width: 1024px) {
  .Breadcrumb {
    width: 102px;
  }

  .Breadcrumb-icon {
    width: auto;
    height: 48px;
    margin-bottom: var(--size-sm);
  }
}
