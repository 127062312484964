.Stat-content {
  display: flex;
  align-items: center;
}

.Stat-content:last-child {
  display: flex;
  align-items: flex-end;
}

.Stat-icon {
  width: var(--size-sm);
  height: var(--size-sm);
  margin-right: var(--size-xs);
}

.Stat-value {
  font-family: var(--font-family);
  font-size: var(--size-base);
  font-weight: 400;
  color: var(--color-white);
}

.Stat-units {
  display: inline;
  font-size: var(--size-sm);
}

.Stat-label {
  margin-left: var(--size-md);

  font-family: var(--font-family);
  font-size: var(--size-xs);
  color: var(--color-white);
  text-align: start;

  opacity: 0.6;
}

.Stat.small .Stat-value {
  font-size: var(--size-sm);
}

.Stat.small .Stat-units {
  font-size: var(--size-xs);
}

.Stat.small .Stat-label {
  display: none;
}

.Stat.highlight .Stat-content {
  flex-direction: row-reverse;
  gap: var(--size-xxs);
}
.Stat.highlight .Stat-label {
  margin-left: 0;
}
.Stat.highlight .Stat-value {
  font-size: var(--size-base);
}

.Stat.highlight .Stat-units {
  display: none;
}

@media screen and (min-width: 1024px) {
  .Stat-value {
    font-size: var(--size-md);
  }

  .Stat-label {
    font-size: var(--size-sm);
  }
}
