html {
  box-sizing: border-box;
  font-family: sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}

button {
  padding: 0;
  background: transparent;
  border: 0;
  margin: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input,
input:active,
input:focus,
input::placeholder {
  cursor: pointer;

  padding: 0;

  font: inherit;
  color: inherit;

  background: none;
  border: none;
  outline: none;
}
