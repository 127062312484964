.ToolbarButton {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.ToolbarButton-icon {
  display: flex;

  width: 24px;
  height: 24px;
  margin: var(--size-sm) var(--size-lg);
  margin-bottom: var(--size-xs);

  opacity: 0.5;
}

.ToolbarButton-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  padding: var(--size-xxs);
}

.ToolbarButton-title {
  font-family: var(--font-family);
  font-size: var(--size-sm);
  font-weight: 400;
  line-height: var(--size-base);
  color: var(--color-white);
  text-transform: capitalize;
}

.ToolbarButton-subtitle {
  font-family: var(--font-family);
  font-size: var(--size-xs);
  font-weight: 400;
  line-height: var(--size-sm);
  color: var(--color-green);
  text-transform: uppercase;
}

.ToolbarButton.active,
.ToolbarButton:hover,
.ToolbarButton:focus {
  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.576) 2.96%,
    rgba(0, 133, 255, 0.5056) 50.03%,
    rgba(0, 163, 255, 0.5504) 93.31%
  );
  outline: none;
}

.ToolbarButton.active .ToolbarButton-icon path {
  opacity: 1;
  fill: var(--color-green);
}

@media screen and (min-width: 1024px) {
  .ToolbarButton {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 167px;
  }

  .ToolbarButton-icon {
    margin: var(--size-sm);
    margin-right: var(--size-xs);
    opacity: 0.5;
  }

  .ToolbarButton-description {
    align-items: flex-start;
    justify-items: flex-start;
  }

  .ToolbarButton-title {
    font-size: var(--size-base);
  }

  .ToolbarButton-subtitle {
    font-size: var(--size-sm);
    color: var(--color-white);
    text-transform: capitalize;
    opacity: 0.5;
  }

  .ToolbarButton.active .ToolbarButton-subtitle {
    color: var(--color-green);
    opacity: 1;
  }
}
