.SearchResult {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: var(--size-sm);

  text-align: start;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.SearchResult:hover,
.SearchResult:focus {
  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.576) 2.96%,
    rgba(0, 133, 255, 0.5056) 50.03%,
    rgba(0, 163, 255, 0.5504) 93.31%
  );
  backdrop-filter: blur(16px);
  outline: none;
}

.SearchResult-title {
  font-family: var(--font-family);
  font-size: var(--size-md);
  font-weight: 300;
  color: var(--color-white);
  text-transform: capitalize;
}

.SearchResult-subtitle {
  font-family: var(--font-family);
  font-size: var(--size-sm);
  font-weight: 300;
  color: var(--color-white);
  text-transform: capitalize;

  opacity: 0.6;
}

@media screen and (min-width: 1024px) {
  .SearchResult-title {
    font-size: var(--size-base);
  }
  .SearchResult-subtitle {
    font-size: var(--size-base);
  }

  .SearchResult:hover .SearchResult-subtitle {
    color: var(--color-green);
    opacity: 1;
  }
}
