.Toolbar {
  position: absolute;
  z-index: 1;
  top: var(--size-xxs);
  right: var(--size-xxs);

  display: flex;
  flex-direction: row;
  gap: 2px;

  padding-left: var(--size-xxs);

  transition: transform 0.8s ease-in-out;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.Toolbar:not(.isReady) {
  transform: translateY(-100px);
}

.Toolbar-buttonsGroup {
  gap: 1px;
}

.Toolbar-buttonsGroup.web {
  display: none;
}

.Toolbar-button.active {
  display: block;
}

.Toolbar-buttonsGroup.collapsed .Toolbar-button:not(.active) {
  display: none;
}

@media (min-width: 1024px) {
  .Toolbar {
    top: var(--size-lg);
    right: var(--size-md);
    flex-direction: row;
    gap: var(--size-md);
  }

  .Toolbar-buttonsGroup {
    display: flex;
    flex-direction: row;
    gap: var(--size-md);
  }

  .Toolbar-buttonsGroup.web {
    display: inherit;
  }

  .Toolbar-buttonsGroup.mobile {
    display: none;
  }
}
