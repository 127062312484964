.List {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.List-header {
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;

  padding: var(--size-md) 0;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-top: 1px solid;
  border-left: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.List-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-bottom: var(--size-sm);
  padding: 0 var(--size-sm);
  padding-bottom: var(--size-md);

  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.List-icon {
  width: 38px;
  height: 38px;
}

.List-heading {
  margin-left: var(--size-md);

  font-family: var(--font-family);
  font-size: var(--size-lg);
  font-weight: 500;
  color: var(--color-white);
}

.List-mainStat {
  min-width: 100%;
  margin-top: var(--size-sm);
}

.List-content {
  flex: 1;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.List-icon path {
  fill: rgba(255, 255, 255, 0.4);
}

.List-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 40px;
  row-gap: var(--size-sm);
  column-gap: var(--size-sm);
  align-items: center;

  margin-top: var(--size-sm);
  padding: 0 var(--size-sm);
}

.List-item {
  cursor: pointer;

  width: 100%;
  margin-left: -1px;
  padding: var(--size-md) var(--size-sm);

  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.List-content li {
  margin-top: -1px;
}
.List-content li:first-of-type {
  margin-top: 1px;
}

.List-item:focus {
  outline: none;
}

.List-item.hover,
.List-item:hover,
.List-item:focus {
  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.576) 2.96%,
    rgba(0, 133, 255, 0.5056) 50.03%,
    rgba(0, 163, 255, 0.5504) 93.31%
  );
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(16, 255, 212, 1),
      rgba(16, 255, 212, 0.12)
    )
    1;
}

.List-itemHeader {
  display: flex;
  align-items: center;
  text-align: start;
}

.List-itemIcon {
  width: 48px;
  height: 34px;
}

.List-itemIcon path {
  fill: rgba(255, 255, 255, 0.4);
}

.List-item.active .List-itemIcon path {
  fill: var(--color-green);
}

.List-itemName {
  margin-left: var(--size-sm);

  font-family: var(--font-family);
  font-size: var(--size-base);
  font-weight: 400;
  color: var(--color-white);
}

.List-itemStats {
  display: flex;
  gap: var(--size-sm);
  justify-content: space-between;
  margin-top: var(--size-sm);
}

.List-item.active {
  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(var(--size-base));
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(16, 255, 212, 1),
      rgba(16, 255, 212, 0.12)
    )
    1;
  border-image-outset: 0 0 1px 1px;
}

.List-item.active .List-itemStats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 40px;
  row-gap: var(--size-sm);
  column-gap: var(--size-sm);
  align-items: center;

  margin-top: var(--size-sm);
  padding: 0 var(--size-sm);
}

@media screen and (min-width: 1024px) {
  .List-item.active {
    background: linear-gradient(
      296.29deg,
      rgba(0, 102, 255, 0.216) 2.96%,
      rgba(0, 133, 255, 0.1896) 50.03%,
      rgba(0, 163, 255, 0.2064) 93.31%
    );
    backdrop-filter: blur(var(--size-base));
  }

  .List-item.active .List-itemStats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 40px;
    row-gap: var(--size-sm);
    column-gap: var(--size-sm);
    align-items: center;

    margin-top: var(--size-sm);
    padding: 0 var(--size-sm);
  }

  .List-itemName {
    font-size: var(--size-md);
  }
}
