.Scale {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: var(--size-xxs);
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 var(--size-sm);

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.144) 2.96%,
    rgba(0, 133, 255, 0.1264) 50.03%,
    rgba(0, 163, 255, 0.1376) 93.31%
  );
  backdrop-filter: blur(16px);
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;
}

.Scale-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-md) 0;
}

.Scale-step,
.Scale-stepLabel {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--size-sm);
  color: white;
}

.Scale-stepLabel {
  opacity: 0.6;
}

.Scale-graphic {
  width: 24px;
  height: 216px;
}

@media screen and (min-width: 1024px) {
  .Scale {
    right: var(--size-md);
  }
}
