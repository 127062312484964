.Search {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: flex-start;

  transition: width 0.3s ease-in-out;
}

.Search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  justify-items: flex-start;

  width: 80px;

  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.216) 2.96%,
    rgba(0, 133, 255, 0.1896) 50.03%,
    rgba(0, 163, 255, 0.2064) 93.31%
  );
  backdrop-filter: blur(16px);
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      rgba(157, 226, 255, 0.64),
      rgba(217, 255, 248, 0)
    )
    1;

  transition: width 0.3s ease-in-out;
}

.Search.active .Search-box {
  width: 100%;
  background: linear-gradient(
    296.29deg,
    rgba(0, 102, 255, 0.576) 2.96%,
    rgba(0, 133, 255, 0.5056) 50.03%,
    rgba(0, 163, 255, 0.5504) 93.31%
  );
}

.Search-icon {
  display: flex;
  width: 24px;
  height: 24px;
  margin: var(--size-lg);
}

.Search-input,
.Search-input:focus {
  cursor: text;

  width: calc(100% - 3 * var(--size-lg));
  padding: var(--size-base);

  font-family: var(--font-family);
  font-size: var(--size-lg);
  font-weight: 400;
  color: var(--color-white);

  background: transparent;
}

.Search-results {
  display: flex;
  flex-direction: column;

  width: 167px;
  min-width: 100%;

  transition: 0.3s;
}

@media screen and (min-width: 1024px) {
  .Search  {
    align-self: flex-end;
    width: fit-content;
  }

  .Search-box {
    width: 167px;
  }

  .Search.active .Search-box {
    width: 370px;
  }

  .Search:not(.active) .Search-input {
    display: inherit;
    width: 100%;
  }

  .Search-input,
  .Search-input:focus {
    cursor: text;

    width: calc(100% - 3 * var(--size-base));
    padding: var(--size-sm);

    font-family: var(--font-family);
    font-size: var(--size-base);
    font-weight: 400;
    color: var(--color-white);

    background: transparent;
  }

  .Search-input:focus {
    width: calc(100% - 3 * var(--size-base));
  }

  .Search-icon {
    display: flex;
    width: var(--size-base);
    height: var(--size-base);
    margin: var(--size-base);
  }

  .Search.active .Search-results {
    width: 370px;
  }
}
